<template>
    <div class="sidebar2">  
      <router-link v-if="$store.getters.isAdmin || $store.getters.isProfessor || $store.getters.isOrgAdmin" to="/upcoming">
      <img src="../assets/dashboard.svg">
      <br>
      Dashboard
      </router-link> 

      <router-link v-if="$store.getters.isAdmin || $store.getters.isProfessor || $store.getters.isOrgAdmin" to="/xrlibrary">
      <img src="../assets/podium.svg">
      <br>
      XR Library
      </router-link>  

      <router-link v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" to="/sessions2">
      <img src="../assets/sessions.svg">
      <br>
      Sessions
      </router-link>  

      <router-link v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" to="/users2">
      <img src="../assets/participants.svg">
      <br>
      Users
      </router-link> 

      <router-link v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" :to="$store.getters.avrisyncMvp ? '/devices3' : '/devices2'">
      <img src="../assets/vrglasses1.svg">
      <br>
      Devices
      </router-link> 

      <router-link v-if="$store.getters.isAdmin" to="/library">
      <img src="../assets/books.svg" style="width:30px;">
      <br>
      Experiences
      </router-link> 

      <div class="bottom">
        <a class="support" :href="portalHost + '/supporthub'" target="_blank">
          <img src="../assets/g177.svg">
          <br>
          Support Hub
        </a>
        <div v-if="$store.getters.isAdmin" class="logo">          
          Powered by
          <img src="../assets/avris-logo-dark-no-tag.svg">
        </div>
      </div>
    </div>
</template>



<script>

export default {
  name: 'Sidebar2',
  computed:{
    portalHost(){
      return process.env.VUE_APP_PORTAL_URL
    }  
  },
  methods: {
    toggle() {     
      this.$store.commit('toggleSidebar');      
    }  
  }
}
</script>

<style lang="scss">

.sidebar2{
    width: var(--size-sidebar);        
    background-color: #fff;
    padding: 2px;
    position: fixed;
    left: 0;
    top: var(--size-header);

    @media (min-height: 670px) {
      padding-top: 50px;
    }

    a, a:visited{
      display: block;
      font-size: 12px;
      color: $text-grey;
      padding: 5px;
      text-align: center;
      margin-bottom: 5px;
      text-decoration: none !important;

      img{
        max-width: 100%;
        max-height: 25px;
      }

      @media (min-height: 670px) {
        //font-size: 16px;
        img {
          max-height: 40px;
        }
      }

      &.router-link-active{
        background-color: #edf6ef;
        color: $text-green1;        
      }
    }

    .bottom{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .logo{
        color: #585c5e;
        border-top: 2px solid #ebebeb;
        font-size: 8px;
        padding: 5px 0;
        text-align: center;
        img{
          width:48px;
        }
      }
    }    
}

.themev2 .sidebar2{
  a, a:visited{
    font-size: 12px;
    color: $text-grey;
      &.router-link-active{
        background-color: #edf6ef;
        color: $text-green1;        
      }
  }

  @media (min-width: 1440px){    
    .logo{
      font-size: 10px;
      img {
        width:58px;
      }
    }
  }

  @media (min-width: 1600px){    
    a, a:visited{
      font-size: 14px;
    }
  }

  @media (min-width: 1920px){  
    .logo{
      font-size: 12px;
      img {
        width:78px;
      }
    } 
    a, a:visited{
      font-size: 16px;
    }
  }
}

</style>
